import { GetStaticPropsContext } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import { Copyrights, IntroSignin, Layout } from 'src/components/common'

import { getLocaleFromCtx } from 'src/utils'

export async function getStaticProps(ctx: GetStaticPropsContext) {
  const localeGroups = ['common', 'buttons', 'fields', 'notifications']

  return {
    props: { ...(await serverSideTranslations(getLocaleFromCtx(ctx), localeGroups)) },
  }
}

const Signin = () => {
  return (
    <Layout footer={<Copyrights />}>
      <IntroSignin />
    </Layout>
  )
}

export default Signin
